import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import posthog from "posthog-js";

const dsn = "https://fbaaf9ad2fc14365c6865aa22ddc862d@o397104.ingest.us.sentry.io/4508023815143424"; // replaced in Dockerfile
const posthog_key = "phc_6elvTMHIO6wIQMZP4DJDyTS9dlDs3lK0SFeyfgDdlSg"; // replaced in Dockerfile

function PosthogInit() {
  useEffect(() => {
    if (posthog_key.startsWith("phc")) {
      posthog.init(posthog_key, {
        api_host: "https://us.i.posthog.com",
        person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      });
    }
  }, []);

  return null;
}

Sentry.init({
  dsn,
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>
  );
});
